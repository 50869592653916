<template>
    <div id="index" ref="appRef">
      <div class="bg">
        <!-- <dv-loading>Loading...</dv-loading> -->
        <img class="feack" src="../assets/img/fanhui.png" alt="" @click="back" />
        
        <div class="icon_time">
            <img src="../assets/img/ic_tb_time.png" alt="">
            {{ dateYear }} {{dateWeek}} {{ dateDay }}
        </div>

        <div class="title">九赋生活指挥中心-孵化中心</div>
        <img class="title_bj" src="../assets/img/title_bj.png" alt="">

        <div class="incubate_left">
            <div class="merchant">
                <div class="merchant_title">全部商户GMV统计</div>
                <div class="tabqiehuan">
                    <span @click="handleClick(1)" :class="activeName == 1 ? 'on':''" style="margin-right: 30px;">本周</span>
                    <span @click="handleClick(2)" :class="activeName == 2 ? 'on':''">本月</span>
                </div>
                <div class="tuImg" id="main"></div>
            </div>

            <div class="merchant" style="margin-top: 12px;">
                <div class="merchant_title">签约商户统计</div>
                <div class="tabqiehuan">
                    <span @click="signingClick(1)" :class="signing == 1 ? 'on':''" style="margin-right: 30px;">本周</span>
                    <span @click="signingClick(2)" :class="signing == 2 ? 'on':''">本月</span>
                </div>
                <div class="tuImg" id="signing"></div>
            </div>
        </div>
        <!-- 左边 -->
        <div class="incubate_center">

            <div class="dayGmv">
                <div class="dayGmv_bj"></div>
                <div class="dayGmv_title">
                    <img src="../assets/img/dayGmv_left.png" alt="" />
                    今日GMV
                    <img src="../assets/img/dayGmv_right.png" alt="" />
                </div>
                <div class="price">
                    <div class="num">
                        <CountTo :startVal='+startVal' :endVal='serverCount' :duration='duration' />
                    </div>
                    <!-- <div class="day_num">
                        较昨日 <span>{{Math.floor(this.percent*100)}}%</span>
                        <img src="../assets/img/dayGmv_top.png" alt="">
                    </div> -->

                    <div class="day_num">
                        较昨日 <span :style="{color:Math.floor(this.percent*100)*1 >= 0 ? '#FF0000':'#14FF39'}">{{Math.floor(this.percent*100)}}%</span>
                        <img v-if="Math.floor(this.percent*100)*1 >= 0 " src="../assets/img/dayGmv_top.png" alt="">
                        <img v-else src="../assets/img/dayGmv_botton.png" alt="">
                    </div>


                </div>
                <div class="gmv">
                    <div class="gmv_con">
                        <p>本月GMV</p>
                        <CountTo :startVal='+startVal' :endVal='gmvMount' :duration='duration' />
                    </div>
                    <div class="gmv_con" style="margin-left: auto;">
                        <p>本年累计GMV</p>
                        <CountTo :startVal='+startVal' :endVal='+gmvYear' :duration='duration' />
                    </div>
                </div>                
            </div>

            <div class="shanghu">
                <div class="merchant_title">签约商户（商户数：{{count}}）</div>
                <div class="shanghu_con">
                    <div class="cake">
                        <div class="cake_con">
                            <p>目标：1,000,000</p>
                            <span>已达成：{{ signContractList[0] }}</span>
                        </div>
                        <div class="cake_tu" id="cake_tu1"></div>
                        <div class="cake_text">
                            <p>{{signContractNameList[0]}}</p>
                            <span>在售商品 销售情况</span>
                        </div>
                    </div>
                    <div class="cake">
                        <div class="cake_con">
                            <p>目标：1,000,000</p>
                            <span>已达成：{{signContractList[1]}}</span>
                        </div>
                        <div class="cake_tu" id="cake_tu2"></div>
                        <div class="cake_text">
                            <p>{{signContractNameList[1]}}</p>
                            <span>在售商品 销售情况</span>
                        </div>
                    </div>
                    <div class="cake">
                        <div class="cake_con">
                            <p>目标：1,000,000</p>
                            <span>已达成：{{signContractList[2]}}</span>
                        </div>
                        <div class="cake_tu" id="cake_tu3"></div>
                        <div class="cake_text">
                            <p>{{signContractNameList[2]}}</p>
                            <span>在售商品 销售情况</span>
                        </div>
                    </div>
                    <div class="cake">
                        <div class="cake_con">
                            <p>目标：1,000,000</p>
                            <span>已达成：{{signContractList[3]}}</span>
                        </div>
                        <div class="cake_tu" id="cake_tu4"></div>
                        <div class="cake_text">
                            <p>{{signContractNameList[3]}}</p>
                            <span>在售商品 销售情况</span>
                        </div>
                    </div>
                </div>

                <div class="shanghu_page">
                    <el-pagination
                        background
                        layout="prev, pager, next, jumper"
                        size="medium"
                        :page-size="4"
                        :total="count"
                        @current-change="handleCurrentChange">
                    </el-pagination>
                </div>

            </div>

        </div>
        <!-- 中间 -->
        
        <div class="incubate_right">
            <div class="merchant_title">最佳商户排行TOP10</div>
            <div class="tabqiehuan">
                <span @click="topClick(1)" :class="topIndex == 1 ? 'on':''" style="margin-right: 30px;">本周</span>
                <span @click="topClick(2)" :class="topIndex == 2 ? 'on':''" style="margin-right: 30px;">本月</span>
                <span @click="topClick(3)" :class="topIndex == 3 ? 'on':''">本年</span>
            </div>
            <div class="topList">
                <div class="topList_con" v-for="(item,index) in merchantList" :key="index">
                    <div class="img" v-if="index+1 == 1"><img src="../assets/img/zj_t1.png" alt=""></div>
                    <div class="img" v-else-if="index+1 == 2"><img src="../assets/img/zj_t2.png" alt=""></div>
                    <div class="img" v-else-if="index+1 == 3"><img src="../assets/img/zj_t3.png" alt=""></div>
                    <div class="text" v-else>
                        {{ index+1 }}
                    </div>

                    <div class="titles">
                        <div class="titles_zi">
                            <span>{{item.name}}</span>
                            <span style="color: #34DBFC; text-align: right;">{{ item.total_order_amount }}</span>
                        </div>
                        <div class="titles_jd">
                            <div class="bj"></div>
                            <div class="jianbian" :style="{width:(item.total_order_amount/100000)*100 + '%'}"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 右边 -->
      </div>
    </div>
  </template>
  
<script>

import echarts from 'echarts';
import CountTo from 'vue-count-to';
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js';
import { getRanking,getTodayGMV,getSignContract,getAllGMVList,signShopContract} from '../config/httpApi';

  export default {
    components: {
        CountTo
    },
    mixins: [drawMixin],
    data() {
      return {
        timing: null,
        loading: true,
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        percent:0,
        // dayData:null,
        // yesterdayData:null,
        timeType: 'week',
        weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
        activeName: '1',    //全部商户GMV统计
        signing:'1', //签约商户统计
        topIndex:'1',   //最佳商户排行TOP10
        serverCount: 0,
        gmvMount:0,
        gmvYear:0,

        startVal:0,
        duration: 1000,
        merchantList:[],
        signContractList:[],
        count:0,
        time:[],
        order_amount:[],
        signContractNameList:[],
        page:1,
        nums:[],
        signTime:[]
        // dayGMV:{},
      }
    },
    mounted() {
      this.timeFn()
      this.cancelLoading()
    //   this.signingChart();   //签约商户统计
      this.getRanking()//获取最佳商户排行
    //   this.cakeTu();
    let that = this;

    setInterval(function() {
    //发送AJAX请求的代码
        that.serverCount = 0;
        that.gmvMount = 0;
        that.gmvYear = 0;
        that.percent = 0;
        that.getTodayGMV();//获取今日GMV
    }, 60000); //600000毫秒 = 10分钟
    that.getTodayGMV();//获取今日GMV
    
      this.getSignContract();//获取签约商户信息
     this.getAllGMVList()//获取所以商户信息-折线图
     this.signShopContract()
    //   this.initChart();   //全部商户GMV统计
    },
    beforeDestroy() {
      clearInterval(this.timing)
    },
    methods: {
        back(){
            this.$router.back();
        },
        handleCurrentChange(val){
            this.page=val
            this.getSignContract()
        },
        signShopContract(){//获取签约商户统计数据
            let type1;
                if(this.signing == 1){
                    type1 = 'week';
                }else{
                    type1 = 'month';
                }
            signShopContract({time:type1}).then(res => {
               this.nums=res.nums
                //切割字符串取月份
               this.signTime = res.time.map(item=>{
                    return item.slice(5)
                });
               const chart = echarts.init(document.getElementById('signing'));
            var option;
            option = {
                grid: { //设置折线图显示边界
                    left: '4%',
                    right: '5%',
                    bottom: '22%',
                    top:'10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: this.signTime
                },
                yAxis: {
                    type: 'value',
                    splitLine:{
                        show:true,
                        lineStyle:{
                            type:'dashed',
                            color:'#fff',
                            opacity: '0.3'
                        }
                    }
                },
                series: [
                    {
                        data: this.nums,
                        type: 'bar',
                        barWidth:'30%',
                        lineStyle: {
                            color: '#55C994', // 设置线的颜色
                            width:3,//设置线条宽度
                            type:'solid'  //设置线条显示：'dotted'虚线 'solid'实线
                        },
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                    {
                                        offset: 0,
                                        color: 'rgba(45,238,226,0)'
                                    },
                                    {
                                        offset: 1,
                                        color: '#59F8F3'
                                    }
                                ]) //折线点的颜色
                            },
                        },
                    }
                ],
                textStyle:{
                    fontSize: '26',
                    color: '#fff'
                }
            };
            chart.setOption(option);
               
            })
           
        },
        getAllGMVList() {  //获取所有商户信息-折线图
                let type1;
                if(this.activeName == 1){
                    type1 = 'week';
                }else{
                    type1 = 'month';
                }
                getAllGMVList({time:type1}).then(res => {
                this.time = res.time
                this.time = this.time.map(item=>{
                    return item.slice(5)
                });
                this.order_amount = res.order_amount;
            const chart = echarts.init(document.getElementById('main'));
            var option;
            option = {
                grid: { //设置折线图显示边界
                    left: '4%',
                    right: '5%',
                    bottom: '22%',
                    top:'10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: this.time,
                },
                yAxis: {
                    type: 'value',
                    splitLine:{
                        show:true,
                        lineStyle:{
                            type:'dashed',
                            color:'#fff',
                            opacity: '0.3'
                        }
                    }
                },
                series: [
                    {
                        data: this.order_amount,
                        type: 'line',
                        lineStyle: {
                            color: '#55C994', // 设置线的颜色
                            width:3,//设置线条宽度
                            type:'solid'  //设置线条显示：'dotted'虚线 'solid'实线
                        },
                        itemStyle: {
                            normal: {
                                color:'#fff', //折线点的颜色
                                borderColor: '#ffffff', //拐点边框颜色
                                borderWidth: 2 //拐点边框大小
                            },
                        },
                    }
                ],
                textStyle:{
                    fontSize: '26',
                    color: '#fff'
                }
            };
            chart.setOption(option);
            })
        },
        getRanking() {  //最佳商户排行数据
            let option = {
                time:this.timeType,
                page:1,
                perPage:10 }
            getRanking(option).then(res => {
                this.merchantList = res;
            })
        },
        getTodayGMV(){//获取今日GMV
            getTodayGMV().then(res => {
                this.serverCount = res.dayData;
                this.gmvMount = res.monthData
                this.gmvYear = res.yearData
                this.percent= res.percent
            })
        },
        getSignContract(){//获取签约商户信息
            
            let option = {
                page:this.page,
                perPage:4,
            }
                getSignContract(option).then(res => {
                this.count = res.count;
                this.signContractList= res.list.map(item=>+item.total_order_amount);
                this.signContractNameList= res.list.map(item=>item.name);
               const chart1 = echarts.init(document.getElementById('cake_tu1'));
            var option1;
            option1 = {
                // backgroundColor: '#111',
                polar: {//极坐标系地底板，类似于grid
                    radius: ['70%', '80%'],
                    center: ['50%', '50%'],
                },
                angleAxis: {//角度轴，一个圆圈
                    max: 100,
                    show: false,
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                radiusAxis: {//径向轴，一段线段，type:'category'类目轴，类似的x轴的类目轴
                    type: 'category',
                    show: true,
                    axisLabel: {
                        show: true,
                    },
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: true,
                    },
                },
                series: [{
                    name: '',
                    type: 'bar',//柱状图
                    label: {
                        normal: {
                            formatter: '{b}：{d}%'
                        }
                    },
                    roundCap: true,
                    barWidth: 90,
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(66, 66, 66, .1)',
                    },
                    data:[((this.signContractList[0]/1000000) * 100)],
                    coordinateSystem: 'polar',//采用极坐标系
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{//color为渐变色
                                offset: 0,
                                color: '#DB69C0',
                            },
                            {
                                offset: 1,
                                color: '#FC399A',
                            },
                            ]),
                        },
                    },
                },
                ],
            };
            chart1.setOption(option1);
            const chart2 = echarts.init(document.getElementById('cake_tu2'));
            var option2;
            option2 = {
                 // backgroundColor: '#111',
                 polar: {//极坐标系地底板，类似于grid
                    radius: ['70%', '80%'],
                    center: ['50%', '50%'],
                },
                angleAxis: {//角度轴，一个圆圈
                    max: 100,
                    show: false,
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                radiusAxis: {//径向轴，一段线段，type:'category'类目轴，类似的x轴的类目轴
                    type: 'category',
                    show: true,
                    axisLabel: {
                        show: true,
                    },
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: true,
                    },
                },
                series: [{
                    name: '',
                    type: 'bar',//柱状图
                    roundCap: true,
                    barWidth: 90,
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(66, 66, 66, .1)',
                    },
                    data: [((this.signContractList[1]/1000000) * 100)],
                    coordinateSystem: 'polar',//采用极坐标系
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{//color为渐变色
                                offset: 0,
                                color: '#DB69C0',
                            },
                            {
                                offset: 1,
                                color: '#FC399A',
                            },
                            ]),
                        },
                    },
                },
                ],
            };
            chart2.setOption(option2);
            const chart3 = echarts.init(document.getElementById('cake_tu3'));
            var option3;
            option3 = {
                 // backgroundColor: '#111',
                 polar: {//极坐标系地底板，类似于grid
                    radius: ['70%', '80%'],
                    center: ['50%', '50%'],
                },
                angleAxis: {//角度轴，一个圆圈
                    max: 100,
                    show: false,
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                radiusAxis: {//径向轴，一段线段，type:'category'类目轴，类似的x轴的类目轴
                    type: 'category',
                    show: true,
                    axisLabel: {
                        show: true,
                    },
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: true,
                    },
                },
                series: [{
                    name: '',
                    type: 'bar',//柱状图
                    roundCap: true,
                    barWidth: 90,
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(66, 66, 66, .1)',
                    },
                    data: [((this.signContractList[2]/1000000) * 100)],
                    coordinateSystem: 'polar',//采用极坐标系
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{//color为渐变色
                                offset: 0,
                                color: '#DB69C0',
                            },
                            {
                                offset: 1,
                                color: '#FC399A',
                            },
                            ]),
                        },
                    },
                },
                ],
            };
            chart3.setOption(option3);

            const chart4 = echarts.init(document.getElementById('cake_tu4'));
            var option4;
            option4 = {
                 // backgroundColor: '#111',
                 polar: {//极坐标系地底板，类似于grid
                    radius: ['70%', '80%'],
                    center: ['50%', '50%'],
                },
                angleAxis: {//角度轴，一个圆圈
                    max: 100,
                    show: false,
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                radiusAxis: {//径向轴，一段线段，type:'category'类目轴，类似的x轴的类目轴
                    type: 'category',
                    show: true,
                    axisLabel: {
                        show: true,
                    },
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: true,
                    },
                },
                series: [{
                    name: '',
                    type: 'bar',//柱状图
                    roundCap: true,
                    barWidth: 90,
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(66, 66, 66, .1)',
                    },
                    data: [((this.signContractList[3]/1000000) * 100)],
                    coordinateSystem: 'polar',//采用极坐标系
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{//color为渐变色
                                offset: 0,
                                color: '#DB69C0',
                            },
                            {
                                offset: 1,
                                color: '#FC399A',
                            },
                            ]),
                        },
                    },
                },
                ],
            };
            chart4.setOption(option4);
               
            })
        },
        topClick(type){//最佳商户排行TOP10
            this.topIndex = type;
            if(type=='1'){
                this.timeType='week'
            }
            else if(type=='2'){
                this.timeType='month'
            }else{
                this.timeType='year'
            }
            // console.log(this.timeType);
      this.getRanking()//获取最佳商户排行
        },
        signingClick(type){ //签约商户统计
            this.signing = type;
            this.signShopContract()
        },
        handleClick(type) {  //全部商户GMV统计
            this.activeName = type;
            this.getAllGMVList();
        },
        timeFn() {
            this.timing = setInterval(() => {
            this.dateDay = formatTime(new Date(), 'HH: mm: ss')
            this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
            this.dateWeek = this.weekday[new Date().getDay()]
            }, 1000)
        },
        cancelLoading() {
            setTimeout(() => {
            this.loading = false
            }, 500)
        }
    }
  }
  </script>

<style lang="scss">
    .el-pagination.is-background .el-pager li{
        color: #fff !important;
        background: #253978 !important;
    }
    .el-pagination.is-background .el-pager li.active{
        color: #fff !important;
        background: #4F629F !important;
    }
    .el-pagination.is-background .btn-prev{
        color: #fff !important;
        background: #253978 !important;
    }
    .el-pagination.is-background .btn-next{
        color: #fff !important;
        background: #253978 !important;
    }
    .el-pagination span{
        color: #fff !important;
    }
    .el-pagination__editor.el-input .el-input__inner{
        color: #fff !important;
        background: #253978 !important;
        border:none !important;
    }
</style>
<style lang="scss" scoped>
@import '../assets/scss/index.scss';

// @import '../assets/scss/style.scss';
.bg{
    position: relative;
    .feack{
            width: 27px;
            height: 25px;
            position: absolute;
            top: 16px;
            left: 20px;
            cursor: pointer;
        }
    .icon_time{
        position: absolute;
        top: 19px; 
        right: 30px;
        img{
            width: 18rpx;
            height: 18px;
            margin-right: 5px;
            float: left;
        }
        font-size: 18px;
        font-weight: bold;
    }
    .title{
        width: 683px;
        height: 42px;
        font-size: 30px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 42px;
        background: url("../assets/img/top_bj.png") no-repeat center;
        background-size: 683px 24px;
        margin: 0 auto 0;
        text-align: center;
    }
    .title_bj{
        width: 1920px;
        height: 27px;

    }
    .incubate_left{
        width: 523px;
        float: left;
        overflow: hidden;
        margin: 14px 0 0 20px;
        .merchant{
            width:523px;
            height: 468px;
            background: url("../assets/img/qbsf.png") no-repeat center;
            background-size: 523px 468px;
            // overflow: hidden;
            .merchant_title{
                width: 523px;
                height: 35px;
                font-size: 18px;
                font-weight: 500;
                color: #34DBFC;
                text-align: center;
                line-height: 35px;
                padding-top: 10px;
            }
            .tabqiehuan{
                width: 151px;
                height: 26px;
                background: url("../assets/img/ic_zxjg_tab_n.png") no-repeat center;
                background-size: 151px 26px;
                margin: 12px auto 0;
                text-align: center;
                span{
                    width: 30px;
                    height: 26px;
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(255,255,255,0.8);
                    line-height: 26px;
                    text-align: center;
                    display: inline-block;
                    cursor:pointer;
                }
                .on{
                    color: #34DBFC;
                    border-bottom: 1px solid #34DBFC;
                    padding-bottom: 4px;
                }
            }
            .tuImg{
                width: 523px;
                height: 468px;
                // margin: 0px auto 0;
                // background: #FF0000;
                // opacity: .3;
            }
        }
    }

    .incubate_center{
        width: 747px;
        height: auto;
        overflow: hidden;
        float: left;
        margin: 15px 0 0 42px;
        .dayGmv{
            width: 747px;
            height: 468px;
            background: url('../assets/img/ic_map_bg.png') no-repeat;
            background-size: 747px 468px;
            position: relative;
            .dayGmv_bj{
                width: 698px;
                height: 419px;
                background: rgba(24,122,205,0.2);
                position: absolute;
                inset: 20px;
            }
            .dayGmv_title{
                width: 747px;
                height: 28px;
                font-size: 20px;
                font-weight: 500;
                color: #34DBFC;
                line-height: 28px;
                text-align: center;
                position: absolute;
                left: 0px;
                top: 58px;
            }
            .price{
                width: 747px;
                height: 45px;
                display: flex;
                justify-items: center;
                align-items: center;
                text-align: center;
                position: absolute;
                top: 115px;
                div{
                    flex: 1;
                }
                .num{
                    width: auto;
                    height: 45px;
                    margin-right: 10px;
                    text-align: right;
                    font-size: 36px;
                    padding-top: 12px;
                }
                .day_num{
                    display: inline-block;
                    font-size: 26px;
                    height: 45px;
                    margin-top: 25px;
                    text-align: left;
                    span{
                        color: #FF0000;
                    }
                    img{
                        width: 17px;
                        height: 18px;
                        margin-left: 5px;
                    }
                }
            }

            .gmv{
                width: 698px;
                margin: 0 auto;
                display: flex;
                padding-top: 200px;
                .gmv_con{
                    width: 249px;
                    height: 132px;
                    background: url('../assets/img/day_num_bj.png') no-repeat;
                    background-size: 249px 132px;
                    margin: 36px;
                    p{
                        width: 249px;
                        height: 53px;
                        font-size: 16px;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 53px;
                        text-align: center;
                    }
                    span{
                        display: inline-block;
                        width: 249px;
                        height: 80px;
                        text-align: center;
                        padding-top: 25px;
                        color: #34DBFC;
                        font-size: 26px;
                    }
                }
            }
        }


        .shanghu{
            width: 747px;
            height: 468px;
            background: url('../assets/img/ic_sssp_bg.png') no-repeat;
            background-size: 747px 468px;
            margin-top: 12px;
            .merchant_title{
                width: 747px;
                height: 28px;
                font-size: 20px;
                font-weight: 500;
                color: #34DBFC;
                line-height: 28px;
                text-align: center;
                padding-top: 12px;
            }
            .shanghu_con{
                margin: 70px 30px 0 10px;
                display: flex;
                .cake{
                    flex:0 0 auto;
                    position: relative;
                    .cake_con{
                        width: 150px;
                        height: 150px;
                        box-shadow: inset 0px 1px 12px 6px #203378;
                        border: 1px solid rgba(151,151,151,0);
                        border-radius: 150px;
                        margin: 15px;
                        p{
                            height: 16px;
                            font-size: 11px;
                            font-weight: 500;
                            color: #FFFFFF;
                            line-height: 16px;
                            text-align: center;
                            padding-top: 54px;
                        }
                        span{
                            height: 16px;
                            font-size: 11px;
                            font-weight: 500;
                            color: #FC3A9B;
                            line-height: 16px;
                            text-align: center;
                            display:block;
                            margin-top: 20px;
                            margin-right: -5px;
                        }
                    }
                    .cake_tu{
                        width: 200px;
                        height: 200px;
                        position: absolute;
                        top: -10px;
                        left: -10px;
                    }
                    .cake_text{
                        text-align: center;
                        p{
                            height: 22px;
                            font-size: 16px;
                            font-weight: 500;
                            color: #FFFFFF;
                            line-height: 16px;
                            text-align: center;
                            padding-top: 5px;
                        }
                        span{
                            height: 22px;
                            font-size: 12px;
                            font-weight: 500;
                            color: #1C91CF;
                            line-height: 22px;
                            display: block;
                            margin-top: 12px;
                        }
                    }
                }
                .cake:last-child{
                    margin-left: auto;
                }
            }
            .shanghu_page{
                margin: 40px 30px 0;
                text-align: center;
            }
        }

    }


    .incubate_right{
        width: 523px;
        height: 951px;
        background: url('../assets/img/ic_sssp_zj_bg.png') no-repeat;
        background-size: 523px 951px;
        float: right;
        margin: 16px 20px 0 0;
        .merchant_title{
            width: 523px;
            height: 35px;
            font-size: 18px;
            font-weight: 500;
            color: #34DBFC;
            text-align: center;
            line-height: 35px;
            padding-top: 10px;
        }
        .tabqiehuan{
            width: 214px;
            height: 26px;
            background: url("../assets/img/ic_zxjg_tab_nian.png") no-repeat center;
            background-size: 214px 26px;
            margin: 22px auto 0;
            text-align: center;
            span{
                width: 30px;
                height: 26px;
                font-size: 14px;
                font-weight: 500;
                color: rgba(255,255,255,0.8);
                line-height: 26px;
                text-align: center;
                display: inline-block;
                cursor:pointer;
            }
            .on{
                color: #34DBFC;
                border-bottom: 1px solid #34DBFC;
                padding-bottom: 4px;
            }
        }
        .topList{
            margin: 16px 20px 0px 10px;
            .topList_con{
                margin-bottom: 15px;
                height: 69px;
                overflow: hidden;
                .img{
                    width: 69px;
                    height: 69px;
                    float: left;
                    padding-top: 4px;
                    padding-left: 4px;
                }
                .text{
                    width: 54px;
                    height: 54px;
                    background: #253978;
                    display: inline-block;
                    margin: 6px;
                    font-size: 20px;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 54px;
                    text-align: center;
                    float: left;
                }

                .titles{
                    width: 409px;
                    height: 56px;
                    float: right;
                    .titles_zi{
                        height: 30px;
                        display: flex;
                        span{
                            flex: 1;
                            font-size: 18px;
                            font-weight: 500;
                            color: #FFFFFF;
                            line-height: 25px;
                            margin-top: 6px;
                        }
                    }
                    .titles_jd{
                        width:409px;
                        height: 16px;
                        margin-top: 8px;
                        position: relative;
                        .bj{
                            width:409px;
                            height: 20px;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            background: rgba(255,255,255,0.2);
                        }
                        .jianbian{
                            width:409px;
                            height: 20px;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            // width: 300px;
                            background: linear-gradient(270deg, #09B2FB 0%, #2036E7 100%);
                        }
                    }
                }
            }
        }
    }
}
</style>
  